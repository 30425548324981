export const MenuData = [
    {
        name: "Home",
        href: "/", 
        has_children: false,
        children: [
            {
                name: "Home Demo - 1",
                href: "/",
                has_children: false,
            },
            {
                name: "Home Demo - 2",
                href: "/home_two",
                has_children: false,
            },
        ]
    },
    {
        name: "About",
        href: "/about",
        has_children: false,
    },
    {
        name: "Services",
        href: "/service",
        has_children: false,
        children: [
            {
                name: "Services",
                href: "/service",
                has_children: false,
            },
            {
                name: "Service Details",
                href: "/service_details",
                has_children: false,
            }
        ]
    },
    {
        name: "Testimonials",
        href: "/testimonials",
        has_children: false,
        children: [
            {
                name: "Blog Grid",
                href: "/blog_grid",
                has_children: false,
            },
            {
                name: "Blog With Sidebar",
                href: "/blog_with_sidebar",
                has_children: false,
            },
            {
                name: "Single Blog",
                href: "/blog_details",
                has_children: false,
            }
        ]
    },
    {
        name: "Contact",
        href: "/contact",
        has_children: false,
    },
]