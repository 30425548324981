import { useState } from "react";
import { toast } from 'react-toastify';

function useForm({ additionalData }) {
  const [status, setStatus] = useState('');
  const [response, setResponse] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('loading');
    setResponse('');

    const finalFormEndpoint = e.target.action;
    const data = Array.from(e.target.elements)
      .filter((input) => input.name)
      .reduce((obj, input) => Object.assign(obj, { [input.name]: input.value }), {});

    if (additionalData) {
      Object.assign(data, additionalData);
    }

    fetch(finalFormEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.code !== 200) {
          throw new Error(response.message);
        }

        setResponse(response.data);
        setStatus('success');
        e.target.reset()
      })
      .catch((message) => {

        toast.error(message.toString().replace("Error: ", ""))
        setStatus('error');
      });
  };

  return { handleSubmit, status, response };
}

export default useForm;