import React from 'react'
import { toast } from 'react-toastify'
import { useState } from 'react';
import TrackShipment from '../TrackYourShipment/index'

const ShipmentDetails = (props) => {
    const [trackShipmentSection, setTrackShipmentSection] = useState(false);
    const endpoint = `https://translantic.fly.dev/api/v1/shipments/preview_pdf?${new URLSearchParams({order_number: props.data.tracking_number})}`;
    
    const request = new Request(endpoint,
            {
              method: "GET",
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              mode: "cors",
              cache: "default",
            }
          );

    const handleClick = () => {
        setTrackShipmentSection(true);
        window.scrollTo(0, 450, 'smooth');
    };

    fetch(request)
        .then((response) => response.blob())
        .then((blob) => {              
            const file = window.URL.createObjectURL(blob);
            const iframe = document.querySelector("iframe");
            if (iframe?.src) iframe.src = file;
            document.getElementById("pdf_tracking").style.backgroundImage = "none";
        })
        .catch((message) => {
            toast.error(message.toString().replace("Error: ", ""));
        });

    return (
        <> {!trackShipmentSection &&
            <section className="container container_tracking">
                <div className="track_area_detail">
                    <iframe src="" title='pdf_tracking' id="pdf_tracking" className='iframe_container'></iframe>
                    <hr />
                    <button type="button" className="btn btn-primary" onClick={handleClick}>Track new order</button>
                </div>
            </section>}

            {trackShipmentSection && <TrackShipment />}
        </>
    )
}

export default ShipmentDetails