import React from 'react'
// PrivacyPolicy Area 
const OurPolicies = () => {
    return (
        <>
            <section id="privacy_policy">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text_heading_para">
                                <h3>Privacy Policy</h3>
                                <hr />
                                <p>Allseas Consortium and its subsidiaries and affiliated companies (“Allseas”, “we”, “us” or “our”) respect your privacy and are committed to protecting your personal information.</p>
                                <p>This privacy policy provides details about the personal information that we collect from you, how we use your personal information and your rights regarding the personal information that we hold about you.</p>
                                <p>Please read this policy carefully. By submitting your information to Allseas, you confirm that you have read, understood and agree to the practices described in this policy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurPolicies
