import React from 'react'
// TermsCondition  Area 
const TermsConditionContent = () => {
    return (
        <>
            <section id="privacy_policy">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text_heading_para">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsConditionContent
