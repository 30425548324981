import React from 'react'
// TeamCard Area
const TeamCard = props => {
    return (
        <>
            <div className="team-member">
                <div className="team_inner">
                    <img src={props.img} className="img-responsive" alt="img_team"/>
                </div>
                <div className="team_name">
                    <h4>{props.name}</h4>
                    <p>{props.des}</p>
                </div>
            </div>
        </>
    )
}

export default TeamCard
