import React from 'react'
import FormInput from '../Common/FormInput'
import useForm from "./UseForm"
import { toast } from 'react-toastify';

const RequestQuoteForm = () => {
  let options1 = [
    {
        text: "Freight Type",
        value: ""
    },
    {
        text: "Air",
        value: "air"
    },
    {
        text: "Land",
        value: "land"
    },
    {
        text: "Sea",
        value: "sea"
    },
    {
        text: "Rails",
        value: "rails"
  }
  ]

  let options2 = [
    {
        text: "Incoterms",
        value: ""
    },
    {
        text: "EXW",
        value: "1"
    },
    {
        text: "FCA",
        value: "2"
    },
    {
        text: "FAS",
        value: "3"
    },
    {
        text: "FOB",
        value: "4"
    },
    {
        text: "CFR",
        value: "5"
    },
    {
        text: "CIF",
        value: "6"
    },
    {
        text: "CPT",
        value: "7"
    },
    {
        text: "CIP",
        value: "8"
    },
    {
        text: "DAF",
        value: "9"
    },
    {
        text: "DES",
        value: "10"
    },
    {
        text: "DDU",
        value: "11"
    },
    {
        text: "DDP",
        value: "12"
    }]

  const endpoint = "https://translantic.fly.dev/api/v1/quotes";
  const additionalData = { sent: new Date().toISOString(), status: "pending" };
  const { handleSubmit, status } = useForm({additionalData,});

  if (status === "success") {
      toast.success("Thank you, we will be in touch!")
  }

  return (
    <>
      <section className="request_quote_form_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-sm-12 col-md-12 col-12 my-5 mx-auto">
              <form id="request_form" action={endpoint} onSubmit={handleSubmit} method="POST">
                <div className="row">
                  <div className="col-lg-12">
                  <div className="heading_quote">
                      <h3>Get a quote</h3>
                  </div>
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag="select"
                      classes="form-control"
                      options={options1}
                      name={"frieght_type"}
                      label="Freight Type"
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag="select"
                      classes="form-control"
                      options={options2}
                      name={"incoterm_id"}
                      label="Incoterms"
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag={"input"}
                      type={"text"}
                      name={"departure"}
                      classes={"form-control"}
                      placeholder={"City of departure"}
                      label="City of departure"
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag={"input"}
                      type={"text"}
                      name={"destination"}
                      classes={"form-control"}
                      placeholder={"Delivery city"}
                      label="Delivery city"
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag={"input"}
                      type={"number"}
                      name={"total_gross_weight"}
                      classes={"form-control"}
                      placeholder={"Total gross weight (kg)"}
                      label="Total gross weight (kg)"
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag={"input"}
                      type={"number"}
                      name={"length"}
                      classes={"form-control"}
                      placeholder={"Length (ft)"}
                      label="Length (ft)"
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag={"input"}
                      type={"number"}
                      name={"width"}
                      classes={"form-control"}
                      placeholder={"Width (ft)"}
                      label="Width (ft)"
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag={"input"}
                      type={"number"}
                      name={"height"}
                      classes={"form-control"}
                      placeholder={"Height (ft)"}
                      label="Height (ft)"
                    />
                  </div>
                  <div className="col-lg-12">
                  <div className="heading_quote arae_top">
                      <h3>Your Personal Details</h3>
                  </div>
                  </div>
                  <div className="col-lg-12">
                    <FormInput
                      tag={"input"}
                      type={"text"}
                      name={"name"}
                      classes={"form-control"}
                      placeholder={"Name"}
                      label="Name"
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag={"input"}
                      type={"text"}
                      name={"email"}
                      classes={"form-control"}
                      placeholder={"Email"}
                      label="Your Email"
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormInput
                      tag={"input"}
                      type={"text"}
                      name={"number"}
                      classes={"form-control"}
                      placeholder={"Phone Number"}
                      label="Phone Number"
                    />
                  </div>
                  <div className="col-lg-12">
                    <FormInput
                      tag={"textarea"}
                      type={"text"}
                      name={"message"}
                      classes={"form-control"}
                      placeholder={"Your Messages"}
                      label="Your Messages"
                    />
                  </div>
                  <div className="col-lg-12">
                    <div className="quote_submit_button">
                    <FormInput tag={'button'} val={'Send Messages'} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RequestQuoteForm
