import road from '../../../assets/img/service/serv-1.jpg'
import sea from '../../../assets/img/service/serv-2.jpg'
import air from '../../../assets/img/service/serv-3.jpg'

export const ServiceData = [
    {
        img: road,
        heading: "Road Freight Forwarding",
        para: "Includes packaging, warehousing, vehicle recovery, repair and maintenance, documentation and others.",
        button: "Read More"
    },
    {
        img: air,
        heading: "Air Freight",
        para: "Air freight forwarding is the process of organizing and planning the transport of freight from one point to another by air",
        button: "Read More"
    },
    {
        img: sea,
        heading: "Sea Freight Forwarding",
        para: "Sea freight forwarding consists of strategic planning and execution of goods supply-chain across countries, on behalf of the shippers.",
        button: "Read More"
    }
]