import img1 from '../../../assets/img/svg/house.svg'
import img2 from '../../../assets/img/svg/crm.svg'
import img3 from '../../../assets/img/svg/clock.svg'

export const OurAdventagesData = [
    [
        {
            img: img1,
            heading: "Warehouse Facility",
            para: "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor unde om"
        },
        {
            img: img2,
            heading: "Customer Satisfaction",
            para: "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor unde om"
        },
        {
            img: img3,
            heading: "Real-time Cargo Tracking",
            para: "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor unde om"
        }
    ],
    [
        {
            img: img1,
            heading: "Warehouse Facility",
            para: "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor unde om"
        },
        {
            img: img2,
            heading: "Customer Satisfaction",
            para: "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor unde om"
        },
        {
            img: img3,
            heading: "Real-time Cargo Tracking",
            para: "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor unde om"
        }
    ],
    [
        {
            img: img1,
            heading: "Warehouse Facility",
            para: "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor unde om"
        },
        {
            img: img2,
            heading: "Customer Satisfaction",
            para: "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor unde om"
        },
        {
            img: img3,
            heading: "Real-time Cargo Tracking",
            para: "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor unde om"
        }
    ]
]