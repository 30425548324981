import React from 'react'
import Maps from '../../Common/Map'
import SectionHeading from '../../Common/SectionHeading'

const HomeTwoMap = () => {
    return (
        <>
            <section id="maps_area_common">
                <div className="container">
                    <SectionHeading heading="Ship Smart with Allseas" para="Our headquarters command a strategic position for overseeing the seamless flow of goods across international waters, we are ideally situated to efficiently manage logistics and provide top-notch services to our global clientele."/> .
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="maps-area">
                                <Maps />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeTwoMap