import img1 from '../../../assets/img/common/team1.png'
import img2 from '../../../assets/img/common/team2.png'
import img3 from '../../../assets/img/common/team3.png'
import img4 from '../../../assets/img/common/team4.png'

export const TeamData = [
    {
        img: img4,
        para: "",
        name: "Patrick Boerkamp",
        des: "Manager Production"
    },
    {
        img: img2,
        para: "",
        name: "Pieter Heerema",
        des: "President"
    },
    {
        img: img3,
        para: "",
        name: "André Steenhuis",
        des: "Vice President Innovations"
    },
    {
        img: img1,
        para: "",
        name: "Serge Solntsev",
        des: "Software Engineer"
    }
]