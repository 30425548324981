import img1 from '../../../assets/img/common/feedback1.jpeg'
import img2 from '../../../assets/img/common/feedback2.jpeg'
import img3 from '../../../assets/img/common/feedback3.jpeg'
import img4 from '../../../assets/img/common/feedback4.jpeg'
import img5 from '../../../assets/img/common/feedback5.jpeg'

export const ClientsData = [
    {
        img: img1,
        name: "Ismaeel Sooliman",
        des: "Aloft Abu Dhabi",
        para: `Exceptional service from start to finish! Our shipment arrived ahead of schedule, and the communication throughout the process was outstanding. Highly recommend this shipping company!`
    },
    {
        img: img2,
        name: "Emily Heaps",
        des: "ABC Apparel",
        para: `Impressed with the professionalism and efficiency of this shipping company. Despite some logistical challenges, they handled our cargo with care and ensured timely delivery. Will definitely use their services again.`
    },
    {
        img: img3,
        name: "Darren Orto",
        des: "Lexicon Pharmaceuticals",
        para: `Unfortunately, our shipment encountered delays due to unforeseen circumstances, but the customer support team at this shipping company was incredibly helpful in resolving the issue. Grateful for their dedication to customer satisfaction.`
    },
    {
        img: img4,
        name: "James Owen",
        des: "Furniture Village",
        para: `Overall, a satisfactory experience with this shipping company. While there were minor hiccups along the way, they were promptly addressed, and our goods arrived intact. Solid choice for reliable transportation services.`
    },
    {
        img: img5,
        name: "Danilo Camargo",
        des: "LNG Automotive Parts",
        para: `Five stars for this shipping company! Their customer service is top-notch, and they truly prioritize customer satisfaction. Our shipment was delivered ahead of schedule, and the team was proactive in keeping us updated every step of the way. Couldn't be happier with the service!`
    }
]