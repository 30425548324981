import React from 'react'
import { toast } from 'react-toastify'
import HomeBannerTwo from '../Banner/index'
import { useState } from 'react'

const Tracking = (props) => {
    const [bannerSection, setBannerSection] = useState(false);
    const endpoint = `https://translantic.fly.dev/api/v1/shipments/preview_pdf?${new URLSearchParams({order_number: props.data.tracking_number})}`;
    
    const request = new Request(endpoint,
            {
              method: "GET",
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              mode: "cors",
              cache: "default",
            }
          );

    const handleClick = () => {
        setBannerSection(true);
        window.scrollTo(0, 0, 'smooth');
    };

    fetch(request)
        .then((response) => response.blob())
        .then((blob) => {  
            const file = window.URL.createObjectURL(blob);
            const iframe = document.querySelector("iframe");
            if (iframe?.src) iframe.src = file;
            if (document.getElementById("pdf_tracking"))
                document.getElementById("pdf_tracking").style.backgroundImage = "none";
        })
        .catch((message) => {
            toast.error(message.toString().replace("Error: ", ""));
        });

    return (
        <>  {!bannerSection && 
            <section id="homeTwo_banner_tracking">
                <div className="banner-slider-two">
               
                        <div className="container">
                            <div className="row">
                                <iframe src="" title='pdf_tracking' id="pdf_tracking" className='iframe_container'></iframe>
                                <hr />
                                <button type="button" className="btn btn-primary ml-1 mt-1" onClick={handleClick}>Track new order</button>
                            </div>
                        </div>

                </div>
            </section> }

            {bannerSection && <HomeBannerTwo />}
        </>
    )
}

export default Tracking