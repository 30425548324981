import React from 'react'
import FormInput from '../Common/FormInput'
import useForm from "../Common/UseForm"
import { toast } from 'react-toastify';

const ContactForm = () => {
    const endpoint = "https://translantic.fly.dev/api/v1/enquiries";
    const additionalData = { sent: new Date().toISOString(), };
    const { handleSubmit, status } = useForm({additionalData,});
    
    if (status === "success") {
        toast.success("Thank you, we will be in touch!")
    }
    
    return (
        <> 
            <form action={endpoint} onSubmit={handleSubmit} method="POST">
                <div className="row">
                    <div className="col-lg-12">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'name'}
                            classes={'form-control'}
                            placeholder={'Name'}
                        />
                    </div>
                    <div className="col-lg-12">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            name={'email'}
                            classes={'form-control'}
                            placeholder={'Email'}
                        />
                    </div>
                    <div className="col-lg-12">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'subject'}
                            classes={'form-control'}
                            placeholder={'Subject'}
                        />
                    </div>
                    <div className="col-lg-12">
                        <FormInput
                            tag={'textarea'}
                            type={'text'}
                            name={'message'}
                            classes={'form-control'}
                            placeholder={'Type Your Messages...'}
                        />
                    </div>
                    <div className="col-lg-12">
                        <div className="contact_form_submit">
                            <FormInput
                                tag={'button'}
                                val={'Send'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default ContactForm
