import img1 from '../../../assets/img/blog/blog1.jpg'
import img2 from '../../../assets/img/blog/blog2.jpg'
import img3 from '../../../assets/img/blog/blog3.jpg'
import img4 from '../../../assets/img/blog/blog4.jpg'
import img5 from '../../../assets/img/blog/blog5.jpg'
import img6 from '../../../assets/img/blog/blog6.jpg'
import img7 from '../../../assets/img/blog/blog7.jpg'
import img8 from '../../../assets/img/blog/blog8.jpg'
import img9 from '../../../assets/img/blog/blog9.jpg'

export const BlogData = [
    {
        img: img1,
        heading: "WE HAVE STARTED NEW SECTION IN GROUND SHIPPING",
        para: `Lorem ipsum dolor sit amet, consctetur adip
  iscing elit. Aenean in ante magna.`,
        name: "Khorshad DOE",
        comment: " 6 comments",
        date: "3 july 2020",
        month: "july",
        day: "03"
    },
    {
        img: img2,
        heading: "Fleet Management Comes into Focus",
        para: `Lorem ipsum dolor sit amet, consctetur adip
  iscing elit. Aenean in ante magna.`,
        name: "Jon DOE",
        comment: " 4 comments",
        date: "23 Jan 2020",
        month: "jan",
        day: "23"
    },
    {
        img: img3,
        heading: "New Warehouse Now Operational",
        para: `Lorem ipsum dolor sit amet, consctetur adip
  iscing elit. Aenean in ante magna.`,
        name: "Harish Mondol",
        comment: "36 comments",
        date: "31 july 2020",
        month: "july",
        day: "31"
    },
    {
        img: img4,
        heading: "For European sustainable development week",
        para: `Lorem ipsum dolor sit amet, consctetur adip
  iscing elit. Aenean in ante magna.`,
        name: "Morgan las",
        comment: " 62 comments",
        date: "8 May 2020",
        month: "May",
        day: "08"
    },
    {
        img: img5,
        heading: "Logistics Should Brace for Changes in Air Cargo",
        para: `Lorem ipsum dolor sit amet, consctetur adip
  iscing elit. Aenean in ante magna.`,
        name: "Tren Mark",
        comment: "12 comments",
        date: "23 Aug 2020",
        month: "Aug",
        day: "23"
    },
    {
        img: img6,
        heading: "Distinctively promote real-time strategic warehouses",
        para: `Lorem ipsum dolor sit amet, consctetur adip
  iscing elit. Aenean in ante magna.`,
        name: "Mela Jes",
        comment: "54 comments",
        date: "30 March 2020",
        month: "March",
        day: "30"
    },
    {
        img: img7,
        heading: "Project Logistics: Going the Distance",
        para: `Lorem ipsum dolor sit amet, consctetur adip
  iscing elit. Aenean in ante magna.`,
        name: "Mark Juris",
        comment: "39 comments",
        date: "8 july 2020",
        month: "july",
        day: "08"
    },
    {
        img: img8,
        heading: "SHIPMENTS DELIVERED ON TIME WITH NO HASSLE.",
        para: `Lorem ipsum dolor sit amet, consctetur adip
  iscing elit. Aenean in ante magna.`,
        name: "Aliza anne",
        comment: "52 comments",
        date: "5 july 2020",
        month: "july",
        day: "05"
    },
    {
        img: img9,
        heading: "SPECIAL HANDLING. EXTRA SERVICE",
        para: `Lorem ipsum dolor sit amet, consctetur adip
  iscing elit. Aenean in ante magna.`,
        name: "Robin Kera",
        comment: " 6 comments",
        date: "3 April 2020",
        month: "April",
        day: "03"
    }

]