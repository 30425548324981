import React from 'react'
import SectionHeading from '../Common/SectionHeading'
import FormInput from "../Common/FormInput";
import useForm from "../Common/UseForm"
import ShipmentDetails from './details';

const TrackShipment = () => {
    const endpoint = "https://translantic.fly.dev/api/v1/shipments/verify";
    const additionalData = { sent: new Date().toISOString() };
    const { handleSubmit, status, response } = useForm({additionalData});

    if (status === "success") {
        return <ShipmentDetails data={response} />
    }

    return (
        <>
        <section id="track_shipment_area">
            <div className="container">
                <SectionHeading heading="Track Your Shipment" para="Solving your supply chain needs from end to end, taking the
    complexity out of container shipping. We are at the forefront of developing innovation."/>
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
                        <div className="track_area_form">
                            <form id="track_form_area" action={endpoint} onSubmit={handleSubmit} method="POST">
                                <FormInput
                                    tag={'input'}
                                    type={'text'}
                                    name={'order_number'}
                                    classes={'form-control'}
                                    placeholder={'Tracking Number'}
                                    label={'Tracking Number'}
                                />
                                <FormInput
                                    tag={'input'}
                                    type={'email'}
                                    name={'email'}
                                    classes={'form-control'}
                                    placeholder={'Email Address'}
                                    label={'Email Address'}
                                />
                                <div className="track_now_btn">
                                    <FormInput tag={'button'} val={'Track Now'} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default TrackShipment

