import React from 'react'
import {Link} from 'react-router-dom';
import FormInput from '../../Common/FormInput'
import useForm from "../../Common/UseForm"
import Tracking from './tracking';

const HomeBannerTwo = () => {

    const endpoint = "https://translantic.fly.dev/api/v1/shipments/verify";
    const additionalData = { sent: new Date().toISOString() };
    const { handleSubmit, status, response } = useForm({additionalData});

    if (status === "success") {
        return <Tracking data={response} />
    }

    return (
        <>
            <section id="homeTwo_banner">
                <div className="banner-slider-two">
               
                        <div className="banner-item-two banner-two-img-one">
                            <div className="container">
                                <div className="banner_two_inner">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="banner-text-two">
                                                <h6>Since 1985</h6>
                                                <h1>Digital Shipping Solution</h1>
                                                <Link className="btn btn-theme" to="/request_quote">Get A Quote</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="track_order_banner">
                                                <h3>Track Your Order</h3>
                                                <form action={endpoint} onSubmit={handleSubmit} method="POST" className="from-tracking">
                                                    <FormInput
                                                        tag={'input'}
                                                        type={'text'}
                                                        name={'order_number'}
                                                        classes={'form-control'}
                                                        placeholder={'Tracking Number'}
                                                    />
                                                    <FormInput
                                                        tag={'input'}
                                                        type={'email'}
                                                        name={'email'}
                                                        classes={'form-control'}
                                                        placeholder={'Email Address'}
                                                    />
                                                    <FormInput
                                                        tag={'button'}
                                                        val={'Track Now'}
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                </div>
            </section>
        </>
    )
}

export default HomeBannerTwo
