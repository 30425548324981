import React from 'react'
import {Link} from 'react-router-dom';
import img1 from '../../../assets/img/common/about-service.jpg'

const HomeTwoAbout = () => {
    return (
        <>
            <section id="service_about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about_service_img">
                                <img src={img1} alt="img-about"/>
                            </div>
                        </div>
                        <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <div className="about_service_text">
                                <div className="heading-left-border">
                                    <h2>A Global Leader in Logistics</h2>
                                </div>
                                <p>We leverage cutting-edge technology, a vast network of partners, and decades of industry expertise to ensure your cargo reaches its destination safely, on time, and within budget. From transportation and warehousing to customs clearance and distribution, our comprehensive suite of services caters to businesses of all sizes and industries.</p>
                                <p>Let us optimize your supply chain and propel your business towards success, wherever your operations take you.</p>
                                <Link to="/about" className="btn btn-theme">More About Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeTwoAbout
